<template>

  <div class="rating"> 
 
    <div 
      v-for="(opt,opt_key) in stars"
      :key="opt_key"
      :class="'star ' + (vl >= opt ? 'full' : 'empty')"
      @click="choose(opt)"
      >
    </div>
    
        
  </div> 

</template>

<script setup>

const emits = defineEmits(['change'])

const props = defineProps({
  cnt: {
    type: Number,
    default: 5
  },
  vl: {
    type: Number,
    default: 0
  },
  editable: {
    type: Boolean,
    default: false
  }
})
 
const stars = computed(() => [...Array(props.cnt).keys()].map(i => i + 1))

const choose = (opt) => {
  if(props.editable){
    emits('change', opt)
  }
}

</script>

<style scoped>
 
 .rating{
    display: flex;
    align-items: center;
 }
 
 .star{
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  margin-right: 3px;
 }
 .star.full{
   background-image: url(/images/rating/star_full.svg);
 }
 .star.empty{
   background-image: url(/images/rating/star_empty.svg);
 }


</style>
